.block-link {
    display: block;
}

.align-right {
    text-align: right;
}

.bold-content {
    font-weight: bold;
}


.gutter-bottom {
    margin-bottom: 20px;
    &--small {
        margin-bottom: 10px;
    }
}

.gutter-right {
    margin-right: 20px;
    &--small {
        margin-right: 10px;
    }
}







