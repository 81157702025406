header {
  display: block;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100px;
  background: white;
  @include box-shadow(0, 6px, 7px, rgba(0,0,0,.25), false);
  .container {
    position: relative;
    height: 100px;
  }
}

.header_logo {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 88px;
  & img {
    display: block;
    max-height: 100%;
  }
}

nav {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 48px ;
  right: 0;
  //display: block;
  //position: relative;
  //float: right;
  //right:0;
}
#main-navigation {
  display: inline-block;
  position: relative;
  li {
    display: inline-block;
    padding-right: 29px;
    height: 100px;
    &:last-child {
      padding-right: 0;
    }
    &.active a {
        color: $blue;
    }
    a {
      color: $font-gray;
      font-weight: 600;
      height: 160px;
      text-transform: uppercase;
      text-decoration: none;
      vertical-align: middle;
      &:hover, &:active, &:focus, .active, .current_page_item {
        color: $blue;
      }
    }
  }
}

#nav-trigger {
  display: none;
  position: absolute;
  top: 0;
  right: 20px;
  list-style: none;
  z-index: 1000;
}
.nav-trigger-link {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  z-index: 1000;
}

.nav-trigger-link span {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -2px;
  -webkit-transition: background .3s .3s;
  -moz-transition: background .3s .3s;
  transition: background .3s .3s;
}
.nav-trigger-link span, .nav-trigger-link span::before, .nav-trigger-link span::after {
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 24px;
  background: $fontBlack;
}

.nav-trigger-link.nav-is-visible span {
  background: transparent;
}
.nav-trigger-link.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}
.nav-trigger-link.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}
.nav-trigger-link.nav-is-visible span::before, .nav-trigger-link.nav-is-visible span::after {
  background: #2e3233;
}
.nav-trigger-link span::before, .nav-trigger-link span::after {
  content: '';
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  right: 0;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform .3s .3s;
  -moz-transition: -moz-transform .3s .3s;
  transition: transform .3s .3s;
}
.nav-trigger-link span::before {
  top: -6px;
}
.nav-trigger-link span::after {
  top: 6px;
}


@media screen and (max-width: 1064px) {
  #main-navigation {
    display: none;
  }
  #main-navigation.full-screen {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: -20px;
    bottom: 0;
    height: 120%;
    width: 100%;
    padding-top: 100px;
    padding-left: 0;
    margin: 0;
    background: white;
    z-index: 999;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateY(1%);
    -moz-transform: translateY(1%);
    -ms-transform: translateY(1%);
    -o-transform: translateY(1%);
    transform: translateY(1%);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
    li {
      display: block;
      height: 60px;
      width: 100%;
      padding: 0;
      vertical-align: middle;
    }
  }
  #nav-trigger {
    display: block;
    margin: 0;

  }
}