@import "../fonts/openSans/variables";
@import "../fonts/openSans/mixins";
@import "../fonts/openSans/Light";
@import "../fonts/openSans/LightItalic";
@import "../fonts/openSans/Regular";
@import "../fonts/openSans/Italic";
@import "../fonts/openSans/SemiBold";
@import "../fonts/openSans/SemiBoldItalic";
@import "../fonts/openSans/Bold";
@import "../fonts/openSans/BoldItalic";
@import "../fonts/openSans/ExtraBold";
@import "../fonts/openSans/ExtraBoldItalic";


//Font Awesome.
@import "../fonts/fontAwesome/fontawesome";
@import "../fonts/fontAwesome/solid.scss";
@import "../fonts/fontAwesome/regular.scss";
@import "../fonts/fontAwesome/light.scss";


.user {
  @include fa-icon;
  @extend .fas;

  &:before {
    content: fa-content($fa-var-user);
  }
}

.twitter {
  @include fa-icon;
  @extend .fab;

  &:before {
    content: fa-content($fa-var-twitter);
  }
}