footer {
    margin-top: 120px;
    background-color: $footer-background;
    font-size: 0.875em; // 14px base font for footer
}

.footer {
    h4 {
        font-size: 1.286em;
        font-weight: 600;
    }
    padding-top: 76px;
    padding-bottom: 76px;
    p, li {
        line-height: 24px;
    }
    .recent_news {
        list-style: none;
        padding: 0;
        li {
            padding: 0 0 16px 0;
            &:last-child {
                padding: 0;
            }
            time {
                display: block;
                text-align: right;
                font-size: 	0.929em;
                font-style: italic;
            }
        }
    }
}


.copyright {
    font-size: 	0.929em;
    font-style: italic;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 2px solid $gray;
}