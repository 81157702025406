// Calculate each column width based on number of columns defined
@function calc-column-width($total-cols) {
    $column-width: (100 / $max-column) * $total-cols;
    @return  $column-width + %;
}

// Generate the column selectors and assigns global properties
@mixin gen-col-props($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
    @for $i from (1 + 1) through $max-column {
        $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
    }

    #{$list} {
        position: relative;
        min-height: 1px; // Prevent columns from collapsing when empty
        padding-left:  ceil($default-gutter);
        padding-right: floor($default-gutter);
    }
}

// Generates column selectors and assigns passed column size properties
@mixin grid-factory($col-selector, $i: 1,$list: ".col-#{$col-selector}-#{$i}") {
    @for $i from (1 + 1) through $max-column {
        $list: "#{$list}, .col-#{$col-selector}-#{$i}";
    }
    #{$list} {
        float: left;
    }

    $i: 0;
    @for $i from (0 + 1) through $max-column {
        .col-#{$col-selector}-#{$i} {
            width: calc-column-width($i);
        }
        .col-#{$col-selector}-push-#{$i} {
            left: calc-column-width($i);
        }
        .col-#{$col-selector}-pull-#{$i} {
            right: calc-column-width($i);
        }
        .col-#{$col-selector}-offset-#{$i} {
            margin-left: calc-column-width($i);
        }
    }
}
