/**************************** stage */
$black: #1A1A1A;
$white: #ffffff;
$font-black: #5D5D5D;
$font-gray: #9D9D9D;
$blue: #446AB5;
$green: #5A9813;
$gray: #D8D8D8;
$dark-gray: $font-black;
//$footer-background: #1f1f1f;
$footer-background: #F2F2F2;


$bg-gray: #F2F2F2;

/**
 * Global Variables
 */
$border-radius: 3px;

/*
 * Grid Variables
 */
$max-column: 12;
$default-gutter: 15px;


//$screen-lg-min: 1362px;
$screen-lg-min: 1262px;
$screen-md-min: 972px;
$screen-sm-min: 750px;

$container-width: $screen-lg-min;



/**************************** Social Medial */
$facebook:  #3b5998;
$gplus:     #db4a39;
$twitter:   #00a0d1;
$skype:     #18b7f1;
$linkedin:  #0077b5;
$pinterest: #c8232c;
$dribbble:  #ea4c89;


/**************************** fonts */
$baseFont: em(14);
$fontBlue:  #00AFE9;
$fontBlack: #2c2c2c;
$fontWhite: #ffffff;

/**************************** directory */
$img-directory: '../image';




//https://all-markup-news.com/px-to-em/
html {
  font-size: em(16);
  font-family: 'Open Sans',arial,sans-serif;
  font-weight: lighter;
  color: $font-black;
}

.container {
  //border: 1px solid grey;
}



/**************************** background Colour */
//@include backgroundColor("bgPink" , $red);
//
//
///**************************** border Colour */
//@include borderColor("bcPink" , $red);
//
///**************************** border Colour */
//@include fontColor("fcPink" , $red);