*,
*:after,
*:before {
  outline: none;
}

.accessible *:focus {
  outline: thin dotted;
  & + label {
    outline: thin dotted;
  }
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .accessible *:focus {
    outline: auto 5px -webkit-focus-ring-color;
    & + label {
      outline: auto 5px -webkit-focus-ring-color;
    }
  }
}