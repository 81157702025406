/**************************** Static Header Banner */
.banner-divider {
  display: block;
  height: 100px;
}
.banner_dynamic {
  margin-bottom: 85px;
}
.banner_static {
  display: block;
  position: relative;
  height: 380px;
  color: $fontWhite;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  &_wrapper {
    position: absolute;
    bottom: 66px;
    h1 {
      margin-bottom: 16px;
    }
    .strapline {
      font-size: 2.5em;
    }
  }
  &--overlay {
    @extend .banner_static ;
    &:after {
      content: "";
      background: url(/build/images/banner-overlayer.png) center center no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }
}


/**************************** Google Map Banner*/
.acf-map {
  width: 100%;
  height: 100%;
  margin: 0 0;

  /* fixes potential theme css conflict */
   img {
     max-width: inherit !important;
   }

   button {
    float: none;
    min-width: auto;
    font-size: inherit;
    font-weight: inherit;
    border-radius: none;
  }
}

.gmnoprint img {
  max-width: none;
}
.marker-label {
  h4 {
    margin-bottom: 14px;
  }
  color: $fontBlack;
  line-height: 18px;
  .location-link {
    margin-bottom: 12px;
  }
}



/**************************** Breadcrumb*/
.breadcrumb {
  position: relative;
  padding: 25px 0 85px 0;
  font-size: 0.875em;
  a, span {
    font-weight: 600;
    text-decoration: none;
  }
  .container:before {
    display: block;
    content: "";
    position: absolute;
    top: -5px;
    height: 10px;
    width: 100%;
    max-width: 760px;
    background: $blue;
  }
}

// container
.content-animation {
  display: block;
  position: relative;
}


.corner-decoration {
  position: absolute;
  top: 0;
  left: 2%;
  width: 100px;
  &>div {
    position: relative;
    & span {
      display: inline-block;
      background-color: $blue;
      position: absolute;
      top: 0;
      left: 0
    }
    & .horizontal {
      height: 4px;
      width: 100px;
      left: auto;
      right: 0
    }
    & .vertical {
      height: 250px;
      width: 4px
    }
    & .horizontal {
      height: 4px;
      width: 100px;
      left: auto;
      right: 0
    }
    & .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      left: -4px;
      top: -4px;
      z-index: 1
    }
  }

  &.animate {
    & .horizontal {
      -webkit-animation: drawLineWidth .5s ease-in-out;
      animation: drawLineWidth .5s ease-in-out
    }

    & .vertical {
      -webkit-animation: drawLineHeight .5s ease-in-out backwards;
      animation: drawLineHeight .5s ease-in-out backwards;
      -webkit-animation-delay: .8s;
      animation-delay: .8s
    }
    & .dot {
      -webkit-animation: grow ease-in-out .3s backwards;
      animation: grow ease-in-out .3s backwards;
      -webkit-animation-delay: .5s;
      animation-delay: .5s
    }
  }
}


@-webkit-keyframes drawLineHeight {
  from {
    height: 0
  }
}
@keyframes drawLineHeight {
  from {
    height: 0
  }
}
@-webkit-keyframes drawLineWidth {
  from {
    width: 0
  }
}
@keyframes drawLineWidth {
  from {
    width: 0
  }
}
@-webkit-keyframes grow {
  from {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }
}
@keyframes grow {
  from {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }
}

/**************************** Main Content Container */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: normal;
}
h1 {
  font-size: 4.5em; // 72px
  font-weight: normal;
}
h2 {
  font-size: 3em; // 48px
}
h3{
  font-size: 2.25em; // 36px
}
h4 {
  font-size: 1.5em; // 24px
}
h5 {
  font-size: 1em; // 16px
}

.main-content  {
  &.pad {
    padding: 85px 8.5% 108px 8.5%;
  }
  position: relative;
  h2 {
    margin: 0;
    margin-bottom: 35px;
  }
  h4 {
    margin: 0;
    margin-top: 6px;
    margin-bottom: 30px;
  }
  p {
    margin: 0;
    padding-bottom: 30px;
    line-height: 32px;
  }
  time {
    display: block;
    font-size: 0.875em;
  }
  &.center {
    text-align: center;
  }
  .general-content ul, .general-content ol,
  &.general-content ul, &.general-content ol {
    margin: 0;
    padding-bottom: 30px;
    line-height: 32px;
  }
  .indent-letter-left {
    position: absolute;
    left: 015px;
  }
}

/**************************** Forms */
label {
  font-weight: 100;
  color: $font-black;
}
label, input[type=text], input[type=email], textarea {
  display: block;
  width: 100%;
  margin-bottom: 28px;
  font-family: inherit;
  &:active, &:focus {
    outline: none !important;
  }
}
footer label {
  margin-bottom: 0;
}
input[type=submit] {
  display: block;
}

input[type=text], input[type=email] {
  border: none;
  height: 40px;
  margin-top: 12px;
  font-size: inherit;
  background-color: transparent;
  border-bottom: 1px solid $dark-gray;
  &:active, &:focus  {
    border-color: $blue;
  }
}
textarea {
  border: none;
  margin-top: 12px;
  font-size: inherit;
  background-color: transparent;
  border-bottom: 1px solid $dark-gray;
  &:active, &:focus  {
    border-color: $blue;
  }
}

input[type=submit], button {
  display: block;
  float: right;
  height: 44px;
  min-width: 124px ;
  font-size: 1em;
  font-weight: 100;
  font-family: inherit;
  border: 1px solid $dark-gray;
  background-color: transparent;
  @include rounded-corners(28px);
  &:hover {
    cursor: pointer;
    background-color: $dark-gray;
    color: $fontWhite;
  }
}


/**************************** Tabs */
.tabs {
  margin-top: 10px;
  margin-bottom: 42px;
  text-align: center;
}
.tabs button {
  float: none;
  display: inline-block;
  width: 30%;
  text-transform: uppercase;
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
  &.current {
    background-color: $dark-gray;
    color: $fontWhite;
  }
}

.tab-content {
  display: none;
  &.current {
    display: inherit;
  }
}

@media screen and (max-width: 750px) {
  .tabs button {
    margin-right: 20px;
  }
}

@media screen and (max-width: 535px) {
  .tabs button {
    width: 100%;
    margin-bottom: 12px;
    margin-right: 0;
  }
}


/**************************** League Table */
.league_table_tab {
  h4 {
    margin-top: 0;
    margin-bottom: 14px;
  }
}
.league_table {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0 0 40px 0;
}
.league_table-heading {
  position: relative;
  height: 44px;
  padding: 5px 0 5px 6px;
  margin-bottom: 8px;
  @include rounded-corners(28px);
  background-color: $dark-gray;
  &>div {
    display: inline-block;
  }
  .heading_team {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 54px;
    height: 20px;
    margin: auto 0;
    color: $fontWhite;
  }
  .heading_titles {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px;
    & ul {
      position: relative;
      list-style-type: none;
      margin: 0;
      padding: 0 20px 0 264px;
      text-align: justify;

      li {
        color: $fontWhite;
        display: inline-block;
        width: 50px;
        text-align: center;
      }
      &:after {
        content:' '; display:inline-block; width: 100%; height: 20px;
      }
    }
  }
}

.league_table-content {
  position: relative;
  height: 44px;
  padding: 5px 0 5px 6px;
  margin-bottom: 8px;
  @include rounded-corners(28px);
  background-color: $bg-gray;
  &>div {
    display: inline-block;
  }
  .position {
    display: inline-block;
    height: 34px;
    width: 34px;
    line-height: 34px;
    text-align: center;
    font-weight: 600;
    background-color: $fontWhite;
    @include rounded-corners(88px)
  }
  .team_name {
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-left: 10px;
    color: $blue;
    font-weight: 600;
    width: 220px;
  }
  .score {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px;
    & ul {
      position: relative;
      list-style-type: none;
      margin: 0;
      padding: 0 20px 0 264px;
      text-align: justify;

      li {
        display: inline-block;
        width: 50px;
        text-align: center;
      }
      &:after {
        content:' '; display:inline-block; width: 100%; height: 20px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .league_table-heading span{
    display: none;
  }
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul
  {
    padding-left: 220px;
  }

  .league_table-heading .heading_titles ul li,
  .league_table-content .score ul li {
    width: 10px;
  }
}

@media screen and (max-width: 535px) {
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul {
    padding-left: 205px;
  }

  .league_table-heading .heading_team {
    left: 20px;
  }
  .league_table-content .team_name {
    top: 7px;
  }

  .league_table-content .position {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul {
    padding-left: 165px;
  }
}


/**************************** Feature Table */
.fixture_table {
  position: relative;
  padding: 0;
  margin: 0 0 40px 0;
  list-style-type: none;

  &_tab h4 {
    margin-top: 0;
    margin-bottom: 14px;
  }

  &-heading {
    position: relative;
    height: 44px;
    width: 100%;
    padding: 5px 0 5px 6px;
    margin-bottom: 8px;
    color: $fontWhite;
    background-color: $dark-gray;
    @include rounded-corners(28px);
    & > div {
      position: relative;
      float: left;
      width: 50%;
      padding: 8px;
      text-align: center;
    }
    & .heading-left {
      padding-right: 44px;
    }
    & .heading-right {
      padding-left: 44px;
    }
  }

  &-content {
    display: table;
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    min-height: 60px;
    & .home_team, .away_team {
      display: table-cell;
      position: relative;
      height: 100%;
      width: 48%;
      padding: 6px;
      vertical-align: middle;
      color: $blue;
      font-weight: 600;
      background-color: $bg-gray;
      @include rounded-corners(28px);
    }
    & .home_team {
      text-align: right;
      padding-right: 66px;
      &-time {
        padding-top: 4px;
        color: $fontBlack;
        font-size: 0.75em;
        font-style: italic;
        font-weight: 400;
      }
      &-score {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 6px;
        height: 50px;
        width: 50px;
        margin: auto 0;
        padding-top: 14px;
        text-align: center;
        font-size: 1.125em;
        font-weight: 600;
        background-color: $fontWhite;
        @include rounded-corners(25px)
      }
    }
    & .away_team {
      text-align: left;
      padding-left: 66px;
      &-score {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 6px;
        height: 50px;
        width: 50px;
        margin: auto 0;
        padding-top: 14px;
        text-align: center;
        font-size: 1.125em;
        font-weight: 600;
        background-color: $fontWhite;
        @include rounded-corners(25px)
      }
    }

    & .versus {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 20px;
      width: 20px;
      text-align: center;
      font-size: 1.125em;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 410px) {
  .fixture_table-content .home_team {
    padding-right: 53px;
  }
  .fixture_table-content .away_team {
    padding-left: 53px;
  }
  .fixture_table-content .home_team-score,
  .fixture_table-content .away_team-score {
    height: 40px;
    width: 40px;
    padding-top: 10px;
    @include rounded-corners(20px)
  }
  .fixture_table-content .versus {
    display: none;
  }
}

/**************************** Downloads */

.download {
  margin: 40px 0 0 0 !important;
  padding-bottom: initial !important;
  line-height: inherit !important;
  padding: 0;
  list-style-type: none;
  &-item {
    position: relative;
    padding: 14px 12px;
    background-color: $white;
    border-top: 1px dashed $gray;
    &:last-child {
      border-bottom: 1px dashed $gray;
    }
    &:hover {
      background-color: $bg-gray;
    }
  }
  &-item-file-icon {
    position: absolute;
    top: 10px;
    .far {
      font-size: 1.5em;
    }
  }
  &-item-content {
    padding-left: 30px;
    h5, div {
      display: inline-block;
    }
  }
  &-item-heading {
    width: 100%;
    max-width: 400px;
    margin-bottom: 0;
    color: $blue;
    font-weight: 400;
  }
  &-item-file-size {
    padding-top: 3px;
    width: 240px;
    float: right;
    padding-right: 120px;
    font-size: 0.825em;
  }
  &-item-date {
    padding-top: 3px;
    float: right;
    padding-right: 40px;
    font-size: 0.825em;
    text-align: right;
  }
  &-item-icon {
    position: absolute;
    top: 13px;
    right: 12px;
    a {
      color: inherit;
      &:hover {
        color: $blue;
      }
    }
    .far{
      background-color: transparent;
    }
  }
}

@media screen and (max-width: 934px) {
  .download {
    &-item-file-icon {
      top: 14px;
    }
    &-item-heading {
      padding-bottom: 14px;
    }
    &-item-file-size {
      display: block !important;
      width: auto;
      float: none;
      padding-top: 3px;
      padding-right: 130px;
      font-size: 0.824em;

    }
    &-item-date {
      display: block !important;
      float: none;
      padding-top: 3px;
      padding-right: 40px;
      font-size: 0.750em;
      text-align: left;
    }
  }
}
@media screen and (max-width: 608px) {
  .download {
    &-item {
      padding-bottom: 36px;
    }
    &-item-file-icon {
      top: 14px;
    }
    &-item-icon {
      position: absolute;
      top: initial;
      bottom: 10px;
      right: 12px;
    }
  }
}

/**************************** Accordion */
.accordion-heading {
  display: block;
  position: relative;
  width: 100%;
  background-color: $bg-gray;
  cursor: pointer;
  margin-bottom: 4px;
  color: $blue;
  font-weight: 600;
  height: 44px;
  line-height: 44px;
  padding: 0 50px;

  .fa-map-marker-alt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    height: 29px;
    font-size: 1.813em;
    span {
      position: absolute;
      top: 2px;
      left: 3px;
      display: block;
      width: 16px;
      height: 16px;
      padding-top: 3px;
      color: $fontBlack;
      font-size: 9px;
      font-weight: 600;
      font-family: "Open Sans";
      text-align: center;
      background-color: $white;
      @include rounded-corners(8px);
    }
  }

  .fa-chevron-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: 16px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition:    all 0.2s ease-in-out;
    -o-transition:      all 0.2s ease-in-out;
    -ms-transition:     all 0.2s ease-in-out;
  }
  &.active .fa-chevron-right {
    transform: rotate(90deg); /* CSS3 */
    -moz-transform: rotate(90deg); /* Firefox */
    -webkit-transform: rotate(90deg); /* Webkit */
    -o-transform: rotate(90deg); /* Opera */
    -ms-transform: rotate(90deg); /* IE 9 */
  }
}


.accordion-content {
  display: none;
  padding: 20px 22px 30px 22px;
  line-height: 26px;
  h5 {
    margin-bottom: 14px;
  }
  address {
    font-style: normal;
  }
  &.active {
    display: block;
  }
  [class*="col-"] .club-details {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
}

/**************************** Image Box Opacity */
.boxOpacity {
  position: relative;
  width: 100%;
  img {
    max-width: 1140px;
  }
}
.boxOpacity:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(35, 35, 35, 0.25);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.boxOpacity:hover:after {
  opacity: 0;
}





/**************************** Sponsor Carousel */

.sponsor {
  position: relative;
  height: 315px;
  padding-bottom: 50px;
  margin-top: 50px;
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 20px;
    height: 2px;
    width: 100%;
    background: $gray;
    z-index: 99;
  }
  &_heading_wrapper {
    position: relative;
    text-align: center;
  }
  &_heading-underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index:99;
    margin: auto;
    height: 100%;
    width: 300px;
    background: white;
  }
  h3 {
    position: relative;
    z-index: 100;
    margin: 0 0 38px 0;
    background-color: transparent;
  }
}

.slider div { text-align: center; }
.slider img { margin: auto; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}





