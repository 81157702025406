/*
 * Container styles
 */
html {
    webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}


.container {
    width: auto;
    max-width: $container-width;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.row {
    margin-left: 0;
    margin-right: 0;
    &.sub-margin {
        margin-left: -$default-gutter;
        margin-right: -$default-gutter;
    }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.row:before,
.row:after {
    content: " ";
    display: table;
}

.clearfix:after,
.container:after,
.row:after {
    clear: both;
}

/*
 * Media Styles
 */

@include gen-col-props();

@include grid-factory('xs');
@media screen and (min-width: $screen-sm-min) {
    @include grid-factory('sm');
}
@media screen and (min-width: $screen-md-min) {
    @include grid-factory('md');
}
@media screen and (min-width: $screen-lg-min) {
    @include grid-factory('lg');
}

/*
 * Grid Style Options
 */

.rm-gutter {
    padding-left: 0;
    padding-right: 0;
}

.row.rm-gutter [class*='col-'], .container.rm-gutter [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}

/*
 * Grid additional pull and push options
 */

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

@media screen and (min-width: $screen-sm-min) {
    .sm-pull-left {
        float: left;
    }

    .sm-pull-right {
        float: right;
    }

    .sm-align-right {
        text-align: right;
    }
}
@media screen and (min-width: $screen-md-min) {
    .md-pull-left {
        float: left;
    }

    .md-pull-right {
        float: right;
    }

    .md-align-right {
        text-align: right;
    }
}
@media screen and (min-width: $screen-lg-min) {
    .lg-pull-left {
        float: left;
    }

    .lg-pull-right {
        float: right;
    }

    .lg-align-right {
        text-align: right;
    }
}
