/**************************** Base Font px to em */
$browser-context: 16px;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

/**************************** Background Colour */
@mixin fontColor( $class, $fontColor: $fontBlack){
  .#{$class} {
    color: $fontColor;
  }
}

/**************************** Background Colour */
@mixin backgroundColor($class, $backgroundColor: $darkGray){
  .#{$class} {
    background-color: $backgroundColor;
  }
}

/**************************** Border Colour */
@mixin borderColor($class, $borderColor: $darkGray){
  .#{$class} {
    border-color: $borderColor !important;
  }
}

/* working required on below mixin */
@mixin border( $px: 0, $bstyle: solid, $border-color: $darkGray, $side:''){
  //border-top: $px $bstyle $border-color ;
  @if(str_index($side, "top")){
    border-top: $px $bstyle $border-color;
  }
  @if(str_index($side, "right")){
    border-right: $px $bstyle $border-color;
  }
  @if(str_index($side, "bottom")){
    border-bottom: $px $bstyle $border-color;
  }
  @if(str_index($side, "left")){
    border-left: $px $bstyle $border-color;
  }
}



/**************************** Navigation Second ul Top Border */
@mixin borderCol( $class, $borderCol: $darkGray){
  .#{$class} {
    border-top-color: $borderCol;
  }
}

/**************************** Round Corner For Images*/
@mixin rounded-corners($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin blur($blur: 0.5em) {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  -moz-filter: blur($blur);
  -o-filter: blur($blur);
  -ms-filter: blur($blur);
}

@mixin blurAnimation($blurAnimation: 30px){
  -webkit-filter: blur($blurAnimation); -moz-filter: blur($blurAnimation);
  -o-filter: blur($blurAnimation); -ms-filter: blur($blurAnimation);
  filter: url(#blur); filter: blur($blurAnimation);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-transition: 5s -webkit-filter linear;
  transition: 5s filter linear;
}

@mixin grayscale($grayscale: 0.5em) {
  filter: grayscale($grayscale);
  -webkit-filter: grayscale($grayscale);
  -moz-filter: grayscale($grayscale);
  -o-filter: grayscale($grayscale);
  -ms-filter: grayscale($grayscale);
}


/**************************** Box Shadow*/
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
