@charset "UTF-8";
/**************************** Base Font px to em */
/**************************** Background Colour */
/**************************** Background Colour */
/**************************** Border Colour */
/* working required on below mixin */
/**************************** Navigation Second ul Top Border */
/**************************** Round Corner For Images*/
/**************************** Box Shadow*/
/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/Light/OpenSans-Light.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/Light/OpenSans-Light.woff?v=1.101") format("woff");
  font-weight: 300;
  font-style: normal; }

/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/LightItalic/OpenSans-LightItalic.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/LightItalic/OpenSans-LightItalic.woff?v=1.101") format("woff");
  font-weight: 300;
  font-style: italic; }

/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/Regular/OpenSans-Regular.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/Regular/OpenSans-Regular.woff?v=1.101") format("woff");
  font-weight: normal;
  font-style: normal; }

/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/Italic/OpenSans-Italic.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/Italic/OpenSans-Italic.woff?v=1.101") format("woff");
  font-weight: normal;
  font-style: italic; }

/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/Semibold/OpenSans-Semibold.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/Semibold/OpenSans-Semibold.woff?v=1.101") format("woff");
  font-weight: 600;
  font-style: normal; }

/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/SemiboldItalic/OpenSans-SemiboldItalic.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/SemiboldItalic/OpenSans-SemiboldItalic.woff?v=1.101") format("woff");
  font-weight: 600;
  font-style: italic; }

/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/Bold/OpenSans-Bold.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/Bold/OpenSans-Bold.woff?v=1.101") format("woff");
  font-weight: bold;
  font-style: normal; }

/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/BoldItalic/OpenSans-BoldItalic.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/BoldItalic/OpenSans-BoldItalic.woff?v=1.101") format("woff");
  font-weight: bold;
  font-style: italic; }

/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/ExtraBold/OpenSans-ExtraBold.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/ExtraBold/OpenSans-ExtraBold.woff?v=1.101") format("woff");
  font-weight: 800;
  font-style: normal; }

/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/wp-content/themes/bbl/build/fonts/openSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2?v=1.101") format("woff2"), url("/wp-content/themes/bbl/build/fonts/openSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.101") format("woff");
  font-weight: 800;
  font-style: italic; }

/* END Extrabold Italic */
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.user,
.far,
.fal,
.fab,
.twitter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.fa-pull-left.user,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left,
.fa-pull-left.twitter {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.fa-pull-right.user,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right,
.fa-pull-right.twitter {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neos:before {
  content: "\f612"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poop:before {
  content: "\f619"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-rendact:before {
  content: "\f3e4"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.eot");
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.woff2") format("woff2"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.woff") format("woff"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.ttf") format("truetype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas,
.user {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.eot");
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.woff2") format("woff2"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.woff") format("woff"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.ttf") format("truetype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.eot");
  src: url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.woff2") format("woff2"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.woff") format("woff"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.ttf") format("truetype"), url("/wp-content/themes/bbl/build/fonts/fontAwesome/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

.user {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: -.125em; }
  .user:before {
    content: "\f007"; }

.twitter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: -.125em; }
  .twitter:before {
    content: "\f099"; }

/**************************** stage */
/**
 * Global Variables
 */
/*
 * Grid Variables
 */
/**************************** Social Medial */
/**************************** fonts */
/**************************** directory */
html {
  font-size: 1em;
  font-family: 'Open Sans',arial,sans-serif;
  font-weight: lighter;
  color: #5D5D5D; }

/**************************** background Colour */
/* HTML5 display-role reset for older browsers
 * Internet explorer 9 and above fix
*/
main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

strong {
  font-weight: 600; }

/* Document (Normalize.css)
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

*,
*:after,
*:before {
  outline: none; }

.accessible *:focus {
  outline: thin dotted; }
  .accessible *:focus + label {
    outline: thin dotted; }

@media (-webkit-min-device-pixel-ratio: 0) {
  .accessible *:focus {
    outline: auto 5px -webkit-focus-ring-color; }
    .accessible *:focus + label {
      outline: auto 5px -webkit-focus-ring-color; } }

/*
 * Container styles
 */
html {
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.container {
  width: auto;
  max-width: 1262px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.row {
  margin-left: 0;
  margin-right: 0; }
  .row.sub-margin {
    margin-left: -15px;
    margin-right: -15px; }

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.row:before,
.row:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.row:after {
  clear: both; }

/*
 * Media Styles
 */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media screen and (min-width: 750px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media screen and (min-width: 972px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media screen and (min-width: 1262px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

/*
 * Grid Style Options
 */
.rm-gutter {
  padding-left: 0;
  padding-right: 0; }

.row.rm-gutter [class*='col-'], .container.rm-gutter [class*='col-'] {
  padding-left: 0;
  padding-right: 0; }

/*
 * Grid additional pull and push options
 */
.pull-left {
  float: left; }

.pull-right {
  float: right; }

@media screen and (min-width: 750px) {
  .sm-pull-left {
    float: left; }
  .sm-pull-right {
    float: right; }
  .sm-align-right {
    text-align: right; } }

@media screen and (min-width: 972px) {
  .md-pull-left {
    float: left; }
  .md-pull-right {
    float: right; }
  .md-align-right {
    text-align: right; } }

@media screen and (min-width: 1262px) {
  .lg-pull-left {
    float: left; }
  .lg-pull-right {
    float: right; }
  .lg-align-right {
    text-align: right; } }

/* Fade in and out */
@keyframes fadeIn {
  from {
    top: 0;
    opacity: 0; }
  to {
    top: 60px;
    opacity: 1; } }

@keyframes fadeOut {
  from {
    top: 60px;
    opacity: 1; }
  to {
    top: 0;
    opacity: 0; } }

/* Line scale loader */
@keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
    -o-transform: scaleY(0.4);
    transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes shimmer {
  0% {
    left: -80%; }
  100% {
    left: 100%; } }

.block-link {
  display: block; }

.align-right {
  text-align: right; }

.bold-content {
  font-weight: bold; }

.gutter-bottom {
  margin-bottom: 20px; }
  .gutter-bottom--small {
    margin-bottom: 10px; }

.gutter-right {
  margin-right: 20px; }
  .gutter-right--small {
    margin-right: 10px; }

header {
  display: block;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100px;
  background: white;
  -webkit-box-shadow: 0 6px 7px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 6px 7px rgba(0, 0, 0, 0.25);
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.25); }
  header .container {
    position: relative;
    height: 100px; }

.header_logo {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 88px; }
  .header_logo img {
    display: block;
    max-height: 100%; }

nav {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 48px;
  right: 0; }

#main-navigation {
  display: inline-block;
  position: relative; }
  #main-navigation li {
    display: inline-block;
    padding-right: 29px;
    height: 100px; }
    #main-navigation li:last-child {
      padding-right: 0; }
    #main-navigation li.active a {
      color: #446AB5; }
    #main-navigation li a {
      color: #9D9D9D;
      font-weight: 600;
      height: 160px;
      text-transform: uppercase;
      text-decoration: none;
      vertical-align: middle; }
      #main-navigation li a:hover, #main-navigation li a:active, #main-navigation li a:focus, #main-navigation li a .active, #main-navigation li a .current_page_item {
        color: #446AB5; }

#nav-trigger {
  display: none;
  position: absolute;
  top: 0;
  right: 20px;
  list-style: none;
  z-index: 1000; }

.nav-trigger-link {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  z-index: 1000; }

.nav-trigger-link span {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -2px;
  -webkit-transition: background .3s .3s;
  -moz-transition: background .3s .3s;
  transition: background .3s .3s; }

.nav-trigger-link span, .nav-trigger-link span::before, .nav-trigger-link span::after {
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 24px;
  background: #2c2c2c; }

.nav-trigger-link.nav-is-visible span {
  background: transparent; }

.nav-trigger-link.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg); }

.nav-trigger-link.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg); }

.nav-trigger-link.nav-is-visible span::before, .nav-trigger-link.nav-is-visible span::after {
  background: #2e3233; }

.nav-trigger-link span::before, .nav-trigger-link span::after {
  content: '';
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  right: 0;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform .3s .3s;
  -moz-transition: -moz-transform .3s .3s;
  transition: transform .3s .3s; }

.nav-trigger-link span::before {
  top: -6px; }

.nav-trigger-link span::after {
  top: 6px; }

@media screen and (max-width: 1064px) {
  #main-navigation {
    display: none; }
  #main-navigation.full-screen {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: -20px;
    bottom: 0;
    height: 120%;
    width: 100%;
    padding-top: 100px;
    padding-left: 0;
    margin: 0;
    background: white;
    z-index: 999;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateY(1%);
    -moz-transform: translateY(1%);
    -ms-transform: translateY(1%);
    -o-transform: translateY(1%);
    transform: translateY(1%);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s; }
    #main-navigation.full-screen li {
      display: block;
      height: 60px;
      width: 100%;
      padding: 0;
      vertical-align: middle; }
  #nav-trigger {
    display: block;
    margin: 0; } }

/**************************** Static Header Banner */
.banner-divider {
  display: block;
  height: 100px; }

.banner_dynamic {
  margin-bottom: 85px; }

.banner_static, .banner_static--overlay {
  display: block;
  position: relative;
  height: 380px;
  color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .banner_static_wrapper {
    position: absolute;
    bottom: 66px; }
    .banner_static_wrapper h1 {
      margin-bottom: 16px; }
    .banner_static_wrapper .strapline {
      font-size: 2.5em; }
  .banner_static--overlay:after {
    content: "";
    background: url(/build/images/banner-overlayer.png) center center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5; }

/**************************** Google Map Banner*/
.acf-map {
  width: 100%;
  height: 100%;
  margin: 0 0;
  /* fixes potential theme css conflict */ }
  .acf-map img {
    max-width: inherit !important; }
  .acf-map button {
    float: none;
    min-width: auto;
    font-size: inherit;
    font-weight: inherit;
    border-radius: none; }

.gmnoprint img {
  max-width: none; }

.marker-label {
  color: #2c2c2c;
  line-height: 18px; }
  .marker-label h4 {
    margin-bottom: 14px; }
  .marker-label .location-link {
    margin-bottom: 12px; }

/**************************** Breadcrumb*/
.breadcrumb {
  position: relative;
  padding: 25px 0 85px 0;
  font-size: 0.875em; }
  .breadcrumb a, .breadcrumb span {
    font-weight: 600;
    text-decoration: none; }
  .breadcrumb .container:before {
    display: block;
    content: "";
    position: absolute;
    top: -5px;
    height: 10px;
    width: 100%;
    max-width: 760px;
    background: #446AB5; }

.content-animation {
  display: block;
  position: relative; }

.corner-decoration {
  position: absolute;
  top: 0;
  left: 2%;
  width: 100px; }
  .corner-decoration > div {
    position: relative; }
    .corner-decoration > div span {
      display: inline-block;
      background-color: #446AB5;
      position: absolute;
      top: 0;
      left: 0; }
    .corner-decoration > div .horizontal {
      height: 4px;
      width: 100px;
      left: auto;
      right: 0; }
    .corner-decoration > div .vertical {
      height: 250px;
      width: 4px; }
    .corner-decoration > div .horizontal {
      height: 4px;
      width: 100px;
      left: auto;
      right: 0; }
    .corner-decoration > div .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      left: -4px;
      top: -4px;
      z-index: 1; }
  .corner-decoration.animate .horizontal {
    -webkit-animation: drawLineWidth .5s ease-in-out;
    animation: drawLineWidth .5s ease-in-out; }
  .corner-decoration.animate .vertical {
    -webkit-animation: drawLineHeight .5s ease-in-out backwards;
    animation: drawLineHeight .5s ease-in-out backwards;
    -webkit-animation-delay: .8s;
    animation-delay: .8s; }
  .corner-decoration.animate .dot {
    -webkit-animation: grow ease-in-out .3s backwards;
    animation: grow ease-in-out .3s backwards;
    -webkit-animation-delay: .5s;
    animation-delay: .5s; }

@-webkit-keyframes drawLineHeight {
  from {
    height: 0; } }

@keyframes drawLineHeight {
  from {
    height: 0; } }

@-webkit-keyframes drawLineWidth {
  from {
    width: 0; } }

@keyframes drawLineWidth {
  from {
    width: 0; } }

@-webkit-keyframes grow {
  from {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; } }

@keyframes grow {
  from {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; } }

/**************************** Main Content Container */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: normal; }

h1 {
  font-size: 4.5em;
  font-weight: normal; }

h2 {
  font-size: 3em; }

h3 {
  font-size: 2.25em; }

h4 {
  font-size: 1.5em; }

h5 {
  font-size: 1em; }

.main-content {
  position: relative; }
  .main-content.pad {
    padding: 85px 8.5% 108px 8.5%; }
  .main-content h2 {
    margin: 0;
    margin-bottom: 35px; }
  .main-content h4 {
    margin: 0;
    margin-top: 6px;
    margin-bottom: 30px; }
  .main-content p {
    margin: 0;
    padding-bottom: 30px;
    line-height: 32px; }
  .main-content time {
    display: block;
    font-size: 0.875em; }
  .main-content.center {
    text-align: center; }
  .main-content .general-content ul, .main-content .general-content ol,
  .main-content.general-content ul, .main-content.general-content ol {
    margin: 0;
    padding-bottom: 30px;
    line-height: 32px; }
  .main-content .indent-letter-left {
    position: absolute;
    left: 015px; }

/**************************** Forms */
label {
  font-weight: 100;
  color: #5D5D5D; }

label, input[type=text], input[type=email], textarea {
  display: block;
  width: 100%;
  margin-bottom: 28px;
  font-family: inherit; }
  label:active, label:focus, input[type=text]:active, input[type=text]:focus, input[type=email]:active, input[type=email]:focus, textarea:active, textarea:focus {
    outline: none !important; }

footer label {
  margin-bottom: 0; }

input[type=submit] {
  display: block; }

input[type=text], input[type=email] {
  border: none;
  height: 40px;
  margin-top: 12px;
  font-size: inherit;
  background-color: transparent;
  border-bottom: 1px solid #5D5D5D; }
  input[type=text]:active, input[type=text]:focus, input[type=email]:active, input[type=email]:focus {
    border-color: #446AB5; }

textarea {
  border: none;
  margin-top: 12px;
  font-size: inherit;
  background-color: transparent;
  border-bottom: 1px solid #5D5D5D; }
  textarea:active, textarea:focus {
    border-color: #446AB5; }

input[type=submit], button {
  display: block;
  float: right;
  height: 44px;
  min-width: 124px;
  font-size: 1em;
  font-weight: 100;
  font-family: inherit;
  border: 1px solid #5D5D5D;
  background-color: transparent;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px; }
  input[type=submit]:hover, button:hover {
    cursor: pointer;
    background-color: #5D5D5D;
    color: #ffffff; }

/**************************** Tabs */
.tabs {
  margin-top: 10px;
  margin-bottom: 42px;
  text-align: center; }

.tabs button {
  float: none;
  display: inline-block;
  width: 30%;
  text-transform: uppercase;
  margin-right: 25px; }
  .tabs button:last-child {
    margin-right: 0; }
  .tabs button.current {
    background-color: #5D5D5D;
    color: #ffffff; }

.tab-content {
  display: none; }
  .tab-content.current {
    display: inherit; }

@media screen and (max-width: 750px) {
  .tabs button {
    margin-right: 20px; } }

@media screen and (max-width: 535px) {
  .tabs button {
    width: 100%;
    margin-bottom: 12px;
    margin-right: 0; } }

/**************************** League Table */
.league_table_tab h4 {
  margin-top: 0;
  margin-bottom: 14px; }

.league_table {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0 0 40px 0; }

.league_table-heading {
  position: relative;
  height: 44px;
  padding: 5px 0 5px 6px;
  margin-bottom: 8px;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-color: #5D5D5D; }
  .league_table-heading > div {
    display: inline-block; }
  .league_table-heading .heading_team {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 54px;
    height: 20px;
    margin: auto 0;
    color: #ffffff; }
  .league_table-heading .heading_titles {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px; }
    .league_table-heading .heading_titles ul {
      position: relative;
      list-style-type: none;
      margin: 0;
      padding: 0 20px 0 264px;
      text-align: justify; }
      .league_table-heading .heading_titles ul li {
        color: #ffffff;
        display: inline-block;
        width: 50px;
        text-align: center; }
      .league_table-heading .heading_titles ul:after {
        content: ' ';
        display: inline-block;
        width: 100%;
        height: 20px; }

.league_table-content {
  position: relative;
  height: 44px;
  padding: 5px 0 5px 6px;
  margin-bottom: 8px;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-color: #F2F2F2; }
  .league_table-content > div {
    display: inline-block; }
  .league_table-content .position {
    display: inline-block;
    height: 34px;
    width: 34px;
    line-height: 34px;
    text-align: center;
    font-weight: 600;
    background-color: #ffffff;
    -webkit-border-radius: 88px;
    -moz-border-radius: 88px;
    border-radius: 88px; }
  .league_table-content .team_name {
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-left: 10px;
    color: #446AB5;
    font-weight: 600;
    width: 220px; }
  .league_table-content .score {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 20px; }
    .league_table-content .score ul {
      position: relative;
      list-style-type: none;
      margin: 0;
      padding: 0 20px 0 264px;
      text-align: justify; }
      .league_table-content .score ul li {
        display: inline-block;
        width: 50px;
        text-align: center; }
      .league_table-content .score ul:after {
        content: ' ';
        display: inline-block;
        width: 100%;
        height: 20px; }

@media screen and (max-width: 650px) {
  .league_table-heading span {
    display: none; }
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul {
    padding-left: 220px; }
  .league_table-heading .heading_titles ul li,
  .league_table-content .score ul li {
    width: 10px; } }

@media screen and (max-width: 535px) {
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul {
    padding-left: 205px; }
  .league_table-heading .heading_team {
    left: 20px; }
  .league_table-content .team_name {
    top: 7px; }
  .league_table-content .position {
    display: none; } }

@media screen and (max-width: 350px) {
  .league_table-heading .heading_titles ul,
  .league_table-content .score ul {
    padding-left: 165px; } }

/**************************** Feature Table */
.fixture_table {
  position: relative;
  padding: 0;
  margin: 0 0 40px 0;
  list-style-type: none; }
  .fixture_table_tab h4 {
    margin-top: 0;
    margin-bottom: 14px; }
  .fixture_table-heading {
    position: relative;
    height: 44px;
    width: 100%;
    padding: 5px 0 5px 6px;
    margin-bottom: 8px;
    color: #ffffff;
    background-color: #5D5D5D;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px; }
    .fixture_table-heading > div {
      position: relative;
      float: left;
      width: 50%;
      padding: 8px;
      text-align: center; }
    .fixture_table-heading .heading-left {
      padding-right: 44px; }
    .fixture_table-heading .heading-right {
      padding-left: 44px; }
  .fixture_table-content {
    display: table;
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    min-height: 60px; }
    .fixture_table-content .home_team, .fixture_table-content .away_team {
      display: table-cell;
      position: relative;
      height: 100%;
      width: 48%;
      padding: 6px;
      vertical-align: middle;
      color: #446AB5;
      font-weight: 600;
      background-color: #F2F2F2;
      -webkit-border-radius: 28px;
      -moz-border-radius: 28px;
      border-radius: 28px; }
    .fixture_table-content .home_team {
      text-align: right;
      padding-right: 66px; }
      .fixture_table-content .home_team-time {
        padding-top: 4px;
        color: #2c2c2c;
        font-size: 0.75em;
        font-style: italic;
        font-weight: 400; }
      .fixture_table-content .home_team-score {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 6px;
        height: 50px;
        width: 50px;
        margin: auto 0;
        padding-top: 14px;
        text-align: center;
        font-size: 1.125em;
        font-weight: 600;
        background-color: #ffffff;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px; }
    .fixture_table-content .away_team {
      text-align: left;
      padding-left: 66px; }
      .fixture_table-content .away_team-score {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 6px;
        height: 50px;
        width: 50px;
        margin: auto 0;
        padding-top: 14px;
        text-align: center;
        font-size: 1.125em;
        font-weight: 600;
        background-color: #ffffff;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px; }
    .fixture_table-content .versus {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 20px;
      width: 20px;
      text-align: center;
      font-size: 1.125em;
      font-weight: 600; }

@media screen and (max-width: 410px) {
  .fixture_table-content .home_team {
    padding-right: 53px; }
  .fixture_table-content .away_team {
    padding-left: 53px; }
  .fixture_table-content .home_team-score,
  .fixture_table-content .away_team-score {
    height: 40px;
    width: 40px;
    padding-top: 10px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px; }
  .fixture_table-content .versus {
    display: none; } }

/**************************** Downloads */
.download {
  margin: 40px 0 0 0 !important;
  padding-bottom: initial !important;
  line-height: inherit !important;
  padding: 0;
  list-style-type: none; }
  .download-item {
    position: relative;
    padding: 14px 12px;
    background-color: #ffffff;
    border-top: 1px dashed #D8D8D8; }
    .download-item:last-child {
      border-bottom: 1px dashed #D8D8D8; }
    .download-item:hover {
      background-color: #F2F2F2; }
  .download-item-file-icon {
    position: absolute;
    top: 10px; }
    .download-item-file-icon .far {
      font-size: 1.5em; }
  .download-item-content {
    padding-left: 30px; }
    .download-item-content h5, .download-item-content div {
      display: inline-block; }
  .download-item-heading {
    width: 100%;
    max-width: 400px;
    margin-bottom: 0;
    color: #446AB5;
    font-weight: 400; }
  .download-item-file-size {
    padding-top: 3px;
    width: 240px;
    float: right;
    padding-right: 120px;
    font-size: 0.825em; }
  .download-item-date {
    padding-top: 3px;
    float: right;
    padding-right: 40px;
    font-size: 0.825em;
    text-align: right; }
  .download-item-icon {
    position: absolute;
    top: 13px;
    right: 12px; }
    .download-item-icon a {
      color: inherit; }
      .download-item-icon a:hover {
        color: #446AB5; }
    .download-item-icon .far {
      background-color: transparent; }

@media screen and (max-width: 934px) {
  .download-item-file-icon {
    top: 14px; }
  .download-item-heading {
    padding-bottom: 14px; }
  .download-item-file-size {
    display: block !important;
    width: auto;
    float: none;
    padding-top: 3px;
    padding-right: 130px;
    font-size: 0.824em; }
  .download-item-date {
    display: block !important;
    float: none;
    padding-top: 3px;
    padding-right: 40px;
    font-size: 0.750em;
    text-align: left; } }

@media screen and (max-width: 608px) {
  .download-item {
    padding-bottom: 36px; }
  .download-item-file-icon {
    top: 14px; }
  .download-item-icon {
    position: absolute;
    top: initial;
    bottom: 10px;
    right: 12px; } }

/**************************** Accordion */
.accordion-heading {
  display: block;
  position: relative;
  width: 100%;
  background-color: #F2F2F2;
  cursor: pointer;
  margin-bottom: 4px;
  color: #446AB5;
  font-weight: 600;
  height: 44px;
  line-height: 44px;
  padding: 0 50px; }
  .accordion-heading .fa-map-marker-alt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    height: 29px;
    font-size: 1.813em; }
    .accordion-heading .fa-map-marker-alt span {
      position: absolute;
      top: 2px;
      left: 3px;
      display: block;
      width: 16px;
      height: 16px;
      padding-top: 3px;
      color: #2c2c2c;
      font-size: 9px;
      font-weight: 600;
      font-family: "Open Sans";
      text-align: center;
      background-color: #ffffff;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px; }
  .accordion-heading .fa-chevron-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: 16px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out; }
  .accordion-heading.active .fa-chevron-right {
    transform: rotate(90deg);
    /* CSS3 */
    -moz-transform: rotate(90deg);
    /* Firefox */
    -webkit-transform: rotate(90deg);
    /* Webkit */
    -o-transform: rotate(90deg);
    /* Opera */
    -ms-transform: rotate(90deg);
    /* IE 9 */ }

.accordion-content {
  display: none;
  padding: 20px 22px 30px 22px;
  line-height: 26px; }
  .accordion-content h5 {
    margin-bottom: 14px; }
  .accordion-content address {
    font-style: normal; }
  .accordion-content.active {
    display: block; }
  .accordion-content [class*="col-"] .club-details {
    margin-top: 30px; }
    .accordion-content [class*="col-"] .club-details:first-child {
      margin-top: 0; }

/**************************** Image Box Opacity */
.boxOpacity {
  position: relative;
  width: 100%; }
  .boxOpacity img {
    max-width: 1140px; }

.boxOpacity:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(35, 35, 35, 0.25);
  transition: all 0.5s;
  -webkit-transition: all 0.5s; }

.boxOpacity:hover:after {
  opacity: 0; }

/**************************** Sponsor Carousel */
.sponsor {
  position: relative;
  height: 315px;
  padding-bottom: 50px;
  margin-top: 50px; }
  .sponsor:before {
    display: block;
    content: "";
    position: absolute;
    top: 20px;
    height: 2px;
    width: 100%;
    background: #D8D8D8;
    z-index: 99; }
  .sponsor_heading_wrapper {
    position: relative;
    text-align: center; }
  .sponsor_heading-underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    margin: auto;
    height: 100%;
    width: 300px;
    background: white; }
  .sponsor h3 {
    position: relative;
    z-index: 100;
    margin: 0 0 38px 0;
    background-color: transparent; }

.slider div {
  text-align: center; }

.slider img {
  margin: auto; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/wp-content/themes/bbl/build/fonts/slick-font/slick.eot");
  src: url("/wp-content/themes/bbl/build/fonts/slick-font/slick.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/bbl/build/fonts/slick-font/slick.woff") format("woff"), url("/wp-content/themes/bbl/build/fonts/slick-font/slick.ttf") format("truetype"), url("/wp-content/themes/bbl/build/fonts/slick-font/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

footer {
  margin-top: 120px;
  background-color: #F2F2F2;
  font-size: 0.875em; }

.footer {
  padding-top: 76px;
  padding-bottom: 76px; }
  .footer h4 {
    font-size: 1.286em;
    font-weight: 600; }
  .footer p, .footer li {
    line-height: 24px; }
  .footer .recent_news {
    list-style: none;
    padding: 0; }
    .footer .recent_news li {
      padding: 0 0 16px 0; }
      .footer .recent_news li:last-child {
        padding: 0; }
      .footer .recent_news li time {
        display: block;
        text-align: right;
        font-size: 0.929em;
        font-style: italic; }

.copyright {
  font-size: 0.929em;
  font-style: italic;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 2px solid #D8D8D8; }

